// talking_point_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "checkbox" ]

    connect() {
        this.checkboxTarget.addEventListener('change', this.submitForm.bind(this))
    }

    submitForm() {
        this.checkboxTarget.form.requestSubmit()
    }
}